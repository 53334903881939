// AUTOGENERADO

const ITEM_CATEGORIA = {
  VESTIDO: 'VESTIDO',
  BLUSA_CAMISA: 'BLUSA_CAMISA',
  CARDIGAN: 'CARDIGAN',
  CASACA: 'CASACA',
  CHALECO: 'CHALECO',
  CHOMPA: 'CHOMPA',
  CHOMPITA: 'CHOMPITA',
  POLERA_SWEATSHIRT: 'POLERA_SWEATSHIRT',
  POLO_POLITO: 'POLO_POLITO',
  POLO_T_SHIRT: 'POLO_T_SHIRT',
  TOP_BVD: 'TOP_BVD',
  SACO_BLAZER_BOLERO: 'SACO_BLAZER_BOLERO',
  SACO_BLAZER_CADERA: 'SACO_BLAZER_CADERA',
  SACO_BLAZER_CINTURA: 'SACO_BLAZER_CINTURA',
  SACO_BLAZER_RODILLA: 'SACO_BLAZER_RODILLA',
  SACO_BLAZER_LARGO: 'SACO_BLAZER_LARGO',
  FALDA_MINI: 'FALDA_MINI',
  FALDA_RODILLA: 'FALDA_RODILLA',
  FALDA_3_4: 'FALDA_3_4',
  FALDA_LARGA: 'FALDA_LARGA',
  FALDA_PANTALON: 'FALDA_PANTALON',
  SHORT: 'SHORT',
  SHORT_JEAN: 'SHORT_JEAN',
  PANTALON: 'PANTALON',
  PANTALON_JEAN: 'PANTALON_JEAN',
  LEGGINGS_JEGGINGS: 'LEGGINGS_JEGGINGS',
  ENTERIZO: 'ENTERIZO',
  SASTRE_CONJUNTO: 'SASTRE_CONJUNTO',
  ZAPATOS_BALERINAS: 'ZAPATOS_BALERINAS',
  ZAPATOS_BOTAS: 'ZAPATOS_BOTAS',
  ZAPATOS_BOTINES: 'ZAPATOS_BOTINES',
  ZAPATOS_CHATOS: 'ZAPATOS_CHATOS',
  ZAPATOS_SANDALIAS_CHATOS: 'ZAPATOS_SANDALIAS_CHATOS',
  ZAPATOS_SANDALIAS_TACOS: 'ZAPATOS_SANDALIAS_TACOS',
  ZAPATOS_TACOS: 'ZAPATOS_TACOS',
  ZAPATOS_ZAPATILLAS: 'ZAPATOS_ZAPATILLAS',
  ACCESORIOS_CORREA: 'ACCESORIOS_CORREA',
  ACCESORIOS_GUANTES: 'ACCESORIOS_GUANTES',
  ACCESORIOS_LENTES: 'ACCESORIOS_LENTES',
  ACCESORIOS_RELOJ: 'ACCESORIOS_RELOJ',
  ACCESORIOS_OTROS: 'ACCESORIOS_OTROS',
  ACCESORIOS_BIJOUTERIE_ANILLO: 'ACCESORIOS_BIJOUTERIE_ANILLO',
  ACCESORIOS_BIJOUTERIE_ARETES: 'ACCESORIOS_BIJOUTERIE_ARETES',
  ACCESORIOS_BIJOUTERIE_BROCHE: 'ACCESORIOS_BIJOUTERIE_BROCHE',
  ACCESORIOS_BIJOUTERIE_COLLAR: 'ACCESORIOS_BIJOUTERIE_COLLAR',
  ACCESORIOS_BIJOUTERIE_DIJE: 'ACCESORIOS_BIJOUTERIE_DIJE',
  ACCESORIOS_BIJOUTERIE_PULSERA: 'ACCESORIOS_BIJOUTERIE_PULSERA',
  ACCESORIOS_BIJOUTERIE_OTROS: 'ACCESORIOS_BIJOUTERIE_OTROS',
  BILLETERA_MONEDERO: 'BILLETERA_MONEDERO',
  CARTERA_BOLSO: 'CARTERA_BOLSO',
  CHAL_PUNTA_CAPA_PONCHO: 'CHAL_PUNTA_CAPA_PONCHO',
  GORRO_SOMBRERO: 'GORRO_SOMBRERO',
  MEDIAS: 'MEDIAS',
  MOCHILA_MALETIN_CANGURO: 'MOCHILA_MALETIN_CANGURO',
  PANUELO_BUFANDA_CHALINA: 'PANUELO_BUFANDA_CHALINA',
  MATERNIDAD: 'MATERNIDAD',
  ROPA_DE_BANO: 'ROPA_DE_BANO',
  ROPA_DE_DEPORTE: 'ROPA_DE_DEPORTE',
  OTROS: 'OTROS',
};
export { ITEM_CATEGORIA };

const ITEM_ONLINE_STATUS = {
  INVENTARIADO: 'INVENTARIADO',
  EN_DISPLAY: 'EN_DISPLAY',
  RESERVADO: 'RESERVADO',
  VENDIDO: 'VENDIDO',
  RETIRADO: 'RETIRADO',
  MERMA: 'MERMA',
  DEVUELTO: 'DEVUELTO',
  ROBADO: 'ROBADO',
};
export { ITEM_ONLINE_STATUS };

const ITEM_ONLINE_UNIVERSO = {
  MUJER: 'MUJER',
  INFANTE: 'INFANTE',
};
export { ITEM_ONLINE_UNIVERSO };

const ITEM_ONLINE_SORT_FIELD = {
  NUEVO: 'NUEVO',
  PRECIO_ASC: 'PRECIO_ASC',
  PRECIO_DESC: 'PRECIO_DESC',
  VISTAS: 'VISTAS',
  RELEVANCIA: 'RELEVANCIA',
};
export { ITEM_ONLINE_SORT_FIELD };

const ITEM_ONLINE_FILTER_CATEGORIA = {
  ACCESORIOS_BIJOUTERIE: 'ACCESORIOS_BIJOUTERIE',
  ACCESORIOS: 'ACCESORIOS',
  CARTERA_BOLSO: 'CARTERA_BOLSO',
  BLUSA_CAMISA: 'BLUSA_CAMISA',
  CHOMPA: 'CHOMPA',
  CASACA: 'CASACA',
  FALDA: 'FALDA',
  PANTALON: 'PANTALON',
  PANTALON_JEAN: 'PANTALON_JEAN',
  POLO: 'POLO',
  SACO: 'SACO',
  SHORT: 'SHORT',
  VESTIDO: 'VESTIDO',
  ZAPATOS: 'ZAPATOS',
  OTROS: 'OTROS',
};
export { ITEM_ONLINE_FILTER_CATEGORIA };

const CHECKOUT_STATUS = {
  EN_PROCESO: 'EN_PROCESO',
  PROCESADO: 'PROCESADO',
};
export { CHECKOUT_STATUS };

const ENVIO_STATUS = {
  PENDIENTE: 'PENDIENTE',
  BUSCANDO_ITEMS: 'BUSCANDO_ITEMS',
  EMPAQUETADO: 'EMPAQUETADO',
  ENVIADO: 'ENVIADO',
  ENTREGADO: 'ENTREGADO',
  CANCELADO: 'CANCELADO',
};
export { ENVIO_STATUS };

const ENVIO_TIPO = {
  RECOJO_TIENDA: 'RECOJO_TIENDA',
  ENVIO: 'ENVIO',
};
export { ENVIO_TIPO };

const PAGO_STATUS = {
  PENDIENTE: 'PENDIENTE',
  ESPERANDO_PAGO: 'ESPERANDO_PAGO',
  VALIDANDO_PAGO: 'VALIDANDO_PAGO',
  RECHAZADO: 'RECHAZADO',
  PAGADO: 'PAGADO',
  EXPIRADO: 'EXPIRADO',
  CANCELADO: 'CANCELADO',
};
export { PAGO_STATUS };

const PAGO_TIPO = {
  TARJETA: 'TARJETA',
  DEPOSITO: 'DEPOSITO',
  TRANSFERENCIA: 'TRANSFERENCIA',
};
export { PAGO_TIPO };

const CARRITO_STATUS = {
  EN_PROCESO: 'EN_PROCESO',
  PROCESADO: 'PROCESADO',
  ABANDONADO: 'ABANDONADO',
};
export { CARRITO_STATUS };

const VENTA_ONLINE_STATUS = {
  EN_PROCESO: 'EN_PROCESO',
  PROCESADA: 'PROCESADA',
  ENTREGADA: 'ENTREGADA',
  ANULADA: 'ANULADA',
  CANCELADA: 'CANCELADA',
  EXPIRADA: 'EXPIRADA',
};
export { VENTA_ONLINE_STATUS };

const VENTA_ONLINE_SORT_FIELD = {
  CLIENTE: 'CLIENTE',
  FECHA: 'FECHA',
  STATUS: 'STATUS',
  PAGO_STATUS: 'PAGO_STATUS',
  PAGO_TIPO: 'PAGO_TIPO',
  ENVIO_STATUS: 'ENVIO_STATUS',
  ENVIO_TIPO: 'ENVIO_TIPO',
  ITEMS_CANT: 'ITEMS_CANT',
  MONTO_TOTAL: 'MONTO_TOTAL',
  MONTO_PUNTOS: 'MONTO_PUNTOS',
  COMPROBANTE: 'COMPROBANTE',
};
export { VENTA_ONLINE_SORT_FIELD };

const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};
export { SORT_DIRECTION };

const BLOQUE_PROMOCIONAL_TYPE = {
  BANNER: 'BANNER',
  SELECCION: 'SELECCION',
  SALE: 'SALE',
};
export { BLOQUE_PROMOCIONAL_TYPE };

const ITEM_ONLINE_INFANTE_CATEGORIA = {
  ABRIGO_CASACA_CHALECO: 'ABRIGO_CASACA_CHALECO',
  BLUSA_CAMISA_TOP: 'BLUSA_CAMISA_TOP',
  BODY_MANGA_CORTA: 'BODY_MANGA_CORTA',
  BODY_MANGA_LARGA: 'BODY_MANGA_LARGA',
  CHOMPA_POLERA: 'CHOMPA_POLERA',
  CONJUNTO: 'CONJUNTO',
  ENTERIZO: 'ENTERIZO',
  FALDA: 'FALDA',
  GORRO_SOMBRERO: 'GORRO_SOMBRERO',
  MEDIAS_PANTY: 'MEDIAS_PANTY',
  PANTALON_CON_PIE: 'PANTALON_CON_PIE',
  PANTALON_SIN_PIE: 'PANTALON_SIN_PIE',
  PANTALON_JEAN: 'PANTALON_JEAN',
  PIJAMA: 'PIJAMA',
  PLAYA: 'PLAYA',
  POLO_MANGA_LARGA: 'POLO_MANGA_LARGA',
  POLO_MANGA_CORTA: 'POLO_MANGA_CORTA',
  SHORT: 'SHORT',
  VESTIDO: 'VESTIDO',
  ZAPATOS: 'ZAPATOS',
  OTROS: 'OTROS',
};
export { ITEM_ONLINE_INFANTE_CATEGORIA };

const ITEM_ONLINE_INFANTE_GENERO = {
  NINA: 'NINA',
  NINO: 'NINO',
  UNISEX: 'UNISEX',
};
export { ITEM_ONLINE_INFANTE_GENERO };

const ITEM_ONLINE_INFANTE_TALLA = {
  _0_A_3_MESES: '_0_A_3_MESES',
  _3_A_6_MESES: '_3_A_6_MESES',
  _6_A_9_MESES: '_6_A_9_MESES',
  _9_A_12_MESES: '_9_A_12_MESES',
  _12_A_18_MESES: '_12_A_18_MESES',
  _18_A_24_MESES: '_18_A_24_MESES',
  _2T: '_2T',
  _3T: '_3T',
  _4T: '_4T',
  _5T: '_5T',
  _6T: '_6T',
  _7T: '_7T',
  _8T: '_8T',
  _10T: '_10T',
  _12T: '_12T',
  _17: '_17',
  _18: '_18',
  _19: '_19',
  _20: '_20',
  _21: '_21',
  _22: '_22',
  _23: '_23',
  _24: '_24',
  _25: '_25',
  _26: '_26',
  _27: '_27',
  _28: '_28',
  _29: '_29',
  _30: '_30',
  _31: '_31',
  _32: '_32',
  _33: '_33',
  _34: '_34',
  _35: '_35',
  UNICA: 'UNICA',
};
export { ITEM_ONLINE_INFANTE_TALLA };

const ITEM_ONLINE_INFANTE_ESTADO = {
  NUEVO_CON_ETIQUETA: 'NUEVO_CON_ETIQUETA',
  NUEVO_SIN_ETIQUETA: 'NUEVO_SIN_ETIQUETA',
  COMO_NUEVO: 'COMO_NUEVO',
  _9_SOBRE_10: '_9_SOBRE_10',
  _8_SOBRE_10: '_8_SOBRE_10',
};
export { ITEM_ONLINE_INFANTE_ESTADO };

const ITEMS_RECOMENDADO_CRITERIOS = {
  PROVEEDORA: 'PROVEEDORA',
  TALLA: 'TALLA',
  CATEGORIA: 'CATEGORIA',
  MARCA: 'MARCA',
};
export { ITEMS_RECOMENDADO_CRITERIOS };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ITEM_CATEGORIA,
  ITEM_ONLINE_STATUS,
  ITEM_ONLINE_UNIVERSO,
  ITEM_ONLINE_SORT_FIELD,
  ITEM_ONLINE_FILTER_CATEGORIA,
  CHECKOUT_STATUS,
  ENVIO_STATUS,
  ENVIO_TIPO,
  PAGO_STATUS,
  PAGO_TIPO,
  CARRITO_STATUS,
  VENTA_ONLINE_STATUS,
  VENTA_ONLINE_SORT_FIELD,
  SORT_DIRECTION,
  BLOQUE_PROMOCIONAL_TYPE,
  ITEM_ONLINE_INFANTE_CATEGORIA,
  ITEM_ONLINE_INFANTE_GENERO,
  ITEM_ONLINE_INFANTE_TALLA,
  ITEM_ONLINE_INFANTE_ESTADO,
  ITEMS_RECOMENDADO_CRITERIOS,
};
