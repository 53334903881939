import { createSlice } from '@reduxjs/toolkit';
import {
  actionCreatorCreator,
  addItem,
  removeItem,
  itemExisteEnArray,
  extraReducers,
} from './utils';

const carrito = createSlice({
  name: 'carrito',
  slice: 'carrito',
  initialState: {
    loading: true,
    data: {
      items: [],
    },
  },
  reducers: {
    setData: (state, action) => {
      const index = state.data.items.findIndex(
        (p) => p._id === action.payload._id,
      );
      if (index >= 0) {
        return {
          ...state,
          data: {
            ...state.data,
            items: [
              ...state.data.items
                .slice(0, index)
                .concat(...state.data.items.slice(index + 1)),
            ],
          },
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: [...state.data.items, action.payload],
        },
      };
    },
    setLoading: actionCreatorCreator('loading'),
    setNewCarrito: (state, { payload: data }) => ({
      ...state,
      data,
    }),
    removeItem: (state, { payload: itemId }) => {
      const { items } = state.data;
      const result = {
        ...state,
        data: {
          ...state.data,
          items: removeItem(items, { _id: itemId }),
        },
      };
      return result;
    },
    addRemoveItem: (state, { payload: item }) => {
      const { items } = state.data;
      const itemEnCarrito = itemExisteEnArray(items, item);

      const result = {
        ...state,
        data: {
          ...state.data,
          items: !itemEnCarrito
            ? addItem(items, item)
            : removeItem(items, item),
        },
      };
      return result;
    },
    resetCarrito: (state) => ({
      ...state,
      data: {
        items: [],
      },
    }),
  },
  extraReducers,
});

export default carrito;
