import qs from 'qs';

export const pathToSearchState = (path) => (
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {}
);

export const createQueryString = (searchState) => (
  searchState ? `?${qs.stringify(searchState)}` : ''
);

export const searchStateToUrl = (searchState) => (
  `${(typeof window !== 'undefined') ? window.location.pathname : ''}${createQueryString(searchState)}`
);
