const kebabCase = (str) => str
  && str
    .normalize('NFD')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
    .normalize()
    .replace(/ñ/gi, 'n')
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-');

module.exports.kebabCase = kebabCase;

const isEmpty = (value) => {
  if (value == null) {
    return true;
  }
  if (
    Array.isArray(value)
    || typeof value === 'string'
    || typeof value.splice === 'function'
  ) {
    return !value.length;
  }

  if (typeof value === 'object' && value !== null) {
    return !Object.keys(value).length;
  }
  return true;
};

module.exports.isEmpty = isEmpty;

const pick = (obj, props) => {
  const result = {};
  if (obj === null) {
    return result;
  }
  if (Array.isArray(props) && props.length > 0) {
    props.forEach((p) => {
      if (p && obj[p]) {
        result[p] = obj[p];
      }
    });
  }
  return result;
};

module.exports.pick = pick;

const omit = (obj, props) => {
  const result = { ...obj };
  if (obj === null) {
    return result;
  }
  if (Array.isArray(props) && props.length > 0) {
    props.forEach((p) => {
      if (p) {
        delete result[p];
      }
    });
  }
  return result;
};

module.exports.omit = omit;

const isNil = (value) => value == null;

module.exports.isNil = isNil;

const compact = (array) => {
  const result = [];

  if (array == null || !Array.isArray(array)) {
    return result;
  }

  array.forEach((i) => {
    if (i) {
      result.push(i);
    }
  });
  return result;
};

module.exports.compact = compact;

const isInteger = (value) => Number.isInteger(value);

module.exports.isInteger = isInteger;

const difference = (arr1, arr2) => arr1.filter((x) => !arr2.includes(x));

module.exports.difference = difference;

const omitBy = (obj, func) => {
  const result = { ...obj };
  if (obj === null) {
    return result;
  }
  const keys = Object.keys(obj);
  keys.forEach((k) => {
    if (func(obj[k])) {
      delete result[k];
    }
  });
  return result;
};

module.exports.omitBy = omitBy;
