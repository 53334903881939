export const colorPrimary = '#ff3652';
export const colorPrimaryLighten = '#fe4d67';

export const colorSecondary = '#000';

export const colorBgPrimary = '#fff';
export const colorBgSecondary = '#d8d8d8';

export const color3 = '#D4EEF7';
export const color4 = '#F6F6F6';
export const color5 = '#FDE4E9';

export const colorPrimaryOfertas = '#ff8282';

export const colorAmarillo = '#ffd660';
export const colorAmarilloLigthen = '#ffe28f';

const muiTheme = (theme) => ({
  primaryButton: {
    backgroundColor: colorPrimary,
    border: 0,
    color: colorBgPrimary,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: colorPrimaryLighten,
    },
  },
  outlinedPrimary: {
    border: `2px solid ${colorPrimary}`,
    backgroundColor: 'transparent',
    color: colorPrimary,
    '&:hover': {
      border: `2px solid ${colorPrimary}`,
      backgroundColor: colorPrimary,
      color: colorBgPrimary,
    },
  },
  outlinedSecondary: {
    border: `2px solid ${colorBgPrimary}`,
    borderWidth: '2px !important',
    backgroundColor: 'transparent',
    color: colorBgPrimary,
    '&:hover': {
      border: `2px solid ${colorBgPrimary}`,
      backgroundColor: colorBgPrimary,
      color: colorPrimary,
    },
  },
  disabledButton: {
    border: `2px solid ${colorBgPrimary}`,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  paginationButton: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    borderRadius: '100%',
  },
  paginationButtonPrimary: {
    backgroundColor: colorPrimary,
    color: colorBgPrimary,
    '&:hover': {
      backgroundColor: colorPrimaryLighten,
    },
  },
  sizeLargeButton: {
    fontSize: '1.5em',
  },
});

export default muiTheme;
