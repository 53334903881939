import { createSlice } from '@reduxjs/toolkit';
import { actionCreatorCreator, extraReducers } from './utils';

const favoritos = createSlice({
  name: 'favoritos',
  slice: 'favoritos',
  initialState: {
    loading: false,
    dataFavoritos: {
      items: [],
    },
  },
  reducers: {
    setDataFavoritos: (state, action) => {
      const index = state.dataFavoritos.items.findIndex(
        (p) => p._id === action.payload._id,
      );
      if (index >= 0) {
        return {
          ...state,
          dataFavoritos: {
            items: [
              ...state.dataFavoritos.items
                .slice(0, index)
                .concat(...state.dataFavoritos.items.slice(index + 1)),
            ],
          },
        };
      }
      return {
        ...state,
        dataFavoritos: {
          items: [...state.dataFavoritos.items, action.payload],
        },
      };
    },
    setLoading: actionCreatorCreator('loading'),
  },
  extraReducers,
});

export default favoritos;
