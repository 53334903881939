import { createSlice } from '@reduxjs/toolkit';

import { actionCreatorCreator, extraReducers } from './utils';

const app = createSlice({
  name: 'app',
  slice: 'app',
  initialState: {
    chatVisible: false,
    loadingCharge: false,
    message: null,
    messages: [],
    eventsToTrack: [],
    perfilDatosModalOpen: false,
    sidebarVisible: false,
  },
  reducers: {
    setChatVisible: actionCreatorCreator('chatVisible'),
    messageClear: (state) => ({
      ...state,
      message: null,
    }),
    infoHandle: (state, { payload: content }) => {
      const message = {
        content,
        type: 'info',
      };
      return {
        ...state,
        message,
        messages: [
          { message: content, timestamp: Date.now(), type: 'info' },
          ...state.messages,
        ],
      };
    },
    errorHandle: (state, { payload: error }) => {
      const message = {
        content: error.message.replace('GraphQL error: ', ''),
        type: 'error',
      };
      // Trackjs.track(error);
      return {
        ...state,
        message,
        messages: [
          { ...error, timestamp: Date.now(), type: 'error' },
          ...state.messages,
        ],
      };
    },
    setLoadingCharge: actionCreatorCreator('loadingCharge'),
    pushEventToTrack: (state, { payload }) => ({
      ...state,
      eventsToTrack: [...state.eventsToTrack, payload],
    }),
    clearEventsToTrack: (state) => ({
      ...state,
      eventsToTrack: [],
    }),
    setPerfilDatosModalOpen: actionCreatorCreator('perfilDatosModalOpen'),
    setSidebarVisible: actionCreatorCreator('sidebarVisible'),
    // login: (state, { payload: { username, password } }) => {
    //   Auth.signIn(username, password);
    //   return state;
    // },
  },
  extraReducers,
});

export default app;
