/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import app from './app';
import itemsOnline from './itemsOnline';
import carrito from './carrito';
import favoritos from './favoritos';
import user from './user';
import checkout from './checkout';
import reclamo from './reclamo';
import box from './box';

const reducer = combineReducers({
  app: app.reducer,
  itemsOnline: itemsOnline.reducer,
  carrito: carrito.reducer,
  favoritos: favoritos.reducer,
  user: user.reducer,
  checkout: checkout.reducer,
  reclamo: reclamo.reducer,
  box: box.reducer,
});

export default reducer;
