// import createVanilla from 'zustand/vanilla';
import { useLayoutEffect } from 'react';
import create from 'zustand';
import createContext from 'zustand/context';
import algoliasearch from 'algoliasearch/lite';

import { indexName as indexNameDefault, onSearchStateChange } from './utils';

const zustandContext = createContext();
export const { Provider, useStore } = zustandContext;

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

// eslint-disable-next-line import/no-mutable-exports
let store;

const initialState = {
  indexName: indexNameDefault,
  searchState: {},
  resultsState: null,
};

const getDefaultUniverso = (state) => {
  let ssUniverso = 'mujer';
  if (state?.refinementList?.universo) {
    const { universo } = state.refinementList;
    if (Array.isArray(universo)) {
      if (universo.length > 0) {
        [ssUniverso] = universo;
      }
    } else {
      ssUniverso = universo;
    }
  }
  return ssUniverso;
};

export const initializeStore = (preloadedState = {}, doCreate = create) => (
  doCreate((set, get) => {
    const setSearchState = (searchState) => {
      const ss = JSON.parse(JSON.stringify(searchState));
      const universo = getDefaultUniverso(searchState);
      ss.refinementList = {
        ...(ss.refinementList || {}),
        universo,
      };
      set((state) => (
        {
          ...state,
          searchState: {
            page: 1,
            sortBy: indexNameDefault,
            ...ss,
          },
        }
      ));
    };

    const getSearchState = () => {
      const { searchState } = get();
      return searchState;
    };

    const resetSearchState = () => {
      set((state) => {
        const universo = getDefaultUniverso(state);
        return {
          ...state,
          searchState: {
            page: 1,
            refinementList: {
              universo,
            },
            sortBy: indexNameDefault,
          },
        };
      });
    };

    const onSearchStateChangeBound = onSearchStateChange.bind(null, setSearchState, getSearchState);

    const getUniverso = () => {
      const { searchState } = get();
      return searchState?.refinementList?.universo;
    };

    const setUniverso = (universo) => {
      const { searchState } = get();
      setSearchState({
        ...(searchState || {}),
        refinementList: {
          ...(searchState?.refinementList || {}),
          universo,
        },
      });
    };

    return {
      ...initialState,
      ...preloadedState,
      setIndexName: (indexName) => set((state) => ({ ...state, indexName })),
      setSearchState,
      setResultsState: (resultsState) => set((state) => ({ ...state, resultsState })),
      resetSearchState,
      onSearchStateChange: onSearchStateChangeBound,
      setUniverso,
      getUniverso,
      set: (newState) => set((oldState) => ({
        ...oldState,
        ...newState,
      })),
    };
  })
);

export function useCreateStore(hookInitialState) {
  // For SSR & SSG, always use a new store.
  if (typeof window === 'undefined' && typeof document === 'undefined') {
    return () => initializeStore(hookInitialState);
  }

  // For CSR, always re-use same store.
  store = store ?? initializeStore(hookInitialState);
  // And if initialState changes, then merge states in the next render cycle.
  //
  // eslint complaining
  //   "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (hookInitialState && store) {
      store.setState({
        ...store.getState(),
        ...hookInitialState,
      });
    }
  }, [hookInitialState]);

  return () => store;
}

export default store;
