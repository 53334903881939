import { createSlice } from '@reduxjs/toolkit';
import { actionCreatorCreator, extraReducers } from './utils';

const itemExisteEnArray = (items, item) => {
  const index = items.findIndex((p) => p._id === item._id);
  if (index >= 0) {
    return true;
  }
  return false;
};

const addItem = (items, item) => items.concat([item]);

const removeItem = (items, item) => items.filter((i) => i._id !== item._id);

const box = createSlice({
  name: 'box',
  slice: 'box',
  initialState: {
    loading: true,
    data: {
      items: [],
    },
    montoServicioBox: 50,
  },
  reducers: {
    setLoading: actionCreatorCreator('loading'),
    setNewBox: (state, { payload: data }) => ({
      ...state,
      data,
    }),
    addRemoveItem: (state, { payload: item }) => {
      const { items } = state.data;
      const itemEnBox = itemExisteEnArray(items, item);
      const result = {
        ...state,
        data: {
          ...state.data,
          items: !itemEnBox ? addItem(items, item) : removeItem(items, item),
        },
      };
      return result;
    },
  },
  extraReducers,
});

export default box;
