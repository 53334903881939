import React from 'react';
import Script from 'next/script';
import { stripIndent } from 'common-tags';

const imports = [];

imports.push(
  <Script
    key="culqi"
    src="https://checkout.culqi.com/v2"
    strategy="afterInteractive"
  />,
);

if (process.env.NODE_ENV === 'production') {
  /* eslint-disable react/no-danger */
  imports.push(
    <Script
      key="mailchimp"
      id="mcjs"
      dangerouslySetInnerHTML={{
        __html: stripIndent`
        !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1cfb66cb48b1209aec0658600/d7570cc1a4c15790e5a8a6240.js");
      `,
      }}
      strategy="lazyOnload"
    />,
  );
  imports.push(
    <Script
      id="facebook-pixel"
      key="facebook-pixel"
      type="text/javascript"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: stripIndent`
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}'); // Insert your pixel ID here.
          fbq('track', 'PageView');
        `,
      }}
    />,
  );
  imports.push(
    <React.Fragment key="ga">
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
        strategy="lazyOnload"
      />
      <Script
        id="gtag"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: stripIndent`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
          `,
        }}
      />
    </React.Fragment>,
  );
  imports.push(
    <Script
      id="intercom"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: stripIndent`
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}"
          };

          // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}'
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.envNEXT_PUBLIC_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `,
      }}
    />,
  );
  /* eslint-enable react/no-danger */
}

export default imports;
