// import { version } from './src/lib/version';

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

Promise.all([
  import('@sentry/nextjs'),
  import('@sentry/tracing'),
  import('@sentry/integrations'),
]).then(([Sentry, { BrowserTracing }, { CaptureConsole }]) => {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // release: `lastraperas-web@${version}`,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.5,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    tunnel: '/api/tunnel',
    maxBreadcrumbs: 50,
  });
});
