import { kebabCase } from './lodashFunctions';

export const checkEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const utils = {
  checkEmail,
};

export default utils;

export const currency = (num) => {
  const symbol = 'S/';
  const result = (num || 0)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return symbol + result;
};

export const scrollToId = (id) => {
  const element = document.querySelector(`#${id}`);
  const topPos = element.getBoundingClientRect().top + window.pageYOffset;

  window.scrollTo({
    top: topPos - 65,
    behavior: 'smooth',
  });
};

export const scrollTo = scrollToId;

export const getItemSlug = (item) => `${kebabCase(item.descripcionVenta).substr(0, 50)}-${item._id}`;

export const getItemUrl = (item) => `/items/${getItemSlug(item)}`;

export const filterRangoToStr = (val) => (val ? `${val.min || ''}${val.max ? `,${val.max}` : ''}` : '');

export const intercom = (...args) => window.Intercom && window.Intercom(...args);

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.substring(1);
const hyphen = (word) => word.split("'").map(capitalize).join("'");
export const camelcase = (myStr) => (!myStr
  ? ''
  : myStr
    .toLowerCase()
    .split(' ')
    .map(capitalize)
    .map(hyphen)
    .join(' ')
    .replace(/  +/g, ' '));

export const humanize = (string) => {
  if (string) {
    const str = string
      .toString()
      .trim()
      .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
      .replace(/[-\s]+/g, '_')
      .toLowerCase()
      .replace(/[\W_]+/g, ' ');

    return capitalize(str);
  }
  return '';
};
export const IS_PRODUCTION = process.env.NODE_ENV === 'production' && !process.env.DEVEL;

export const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
