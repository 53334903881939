// eslint-disable-next-line import/no-cycle
import { getStore } from '../store';
import sliceApp from '../reducers/app';
import { IS_BROWSER, IS_PRODUCTION, intercom } from '.';

let userIsIdentified = false;

export const EVENTS = {
  CARRITO_ITEM_ADDED: 'carrito-item-added',
  CARRITO_ITEM_REMOVED: 'carrito-item-removed',
  CHECKOUT_ENVIO: 'checkout-envio',
  CHECKOUT_ENVIO_TIPO: 'checkout-envio-tipo',
  CHECKOUT_PAGO: 'checkout-pago',
  CHECKOUT_PAGO_CODIGO_DESCUENTO: 'checkout-pago-codigo-descuento',
  CHECKOUT_PAGO_ERROR_CULQI: 'checkout-pago-error-culqi',
  CHECKOUT_PAGO_ERROR_SERVER: 'checkout-pago-error-server',
  CHECKOUT_PAGO_SUCCESS: 'checkout-pago-success',
  CHECKOUT_STARTED: 'checkout-started',
  ITEM_VIEW: 'item-view',
  ITEMS_FILTER_CLEAR: 'items-filter-clear',
  ITEMS_FILTER: 'items-filter',
  ITEMS_SORT: 'items-sort',
  PERFIL_DATOS_UPDATED_SIGNUP: 'perfil-datos-updated-signup',
  PERFIL_DIRECCION_UPDATED_SIGNUP: 'perfil-direccion-updated-signup',
  PERFIL_NOT_UPDATED_SIGNUP: 'perfil-not-updated-signup',
  VIEW_PAGE: 'view-page',
  FILTER_CATEGORIA: 'categoria',
  FILTER_TALLA: 'talla',
  FILTER_MARCA: 'marca',
  FILTER_GENERO: 'genero',
  ITEM_RECOMENDADO: 'recomendacion-click',
};

const itemToGtag = (item) => {
  const {
    _id, descripcionVenta, marca, categoria, precioVenta,
  } = item || {};
  return {
    id: _id,
    name: descripcionVenta,
    brand: marca || '',
    category: categoria,
    quantity: 1,
    price: precioVenta.toFixed(2),
  };
};

let ultimoEvento;
let lastTimeoutId;

const triggerLastEventMemory = (event) => {
  if (lastTimeoutId) {
    clearTimeout(lastTimeoutId);
  }

  ultimoEvento = event;
  lastTimeoutId = setTimeout(() => {
    ultimoEvento = undefined;
  }, 1500);
};

const getGtag = () => {
  let { gtag } = window;
  // eslint-disable-next-line no-console
  gtag = gtag
    || ((_, gEvent, gData) => process.env.NEXT_PUBLIC_DEVEL && console.info('gtag', gEvent, gData));
  return gtag;
};

export const trackEvent = (event, metadata, data) => {
  if (event === ultimoEvento) {
    return;
  }

  triggerLastEventMemory(event);

  if (userIsIdentified) {
    intercom('trackEvent', event, metadata);
  }
  const gtag = getGtag();

  if (data) {
    switch (event) {
      case EVENTS.ITEM_VIEW:
        gtag('event', 'view_item', {
          items: [itemToGtag(data)],
        });
        break;
      case EVENTS.CARRITO_ITEM_ADDED:
        gtag('event', 'add_to_cart', {
          items: [itemToGtag(data)],
        });
        break;
      case EVENTS.CARRITO_ITEM_REMOVED:
        gtag('event', 'remove_from_cart', {
          items: [itemToGtag(data)],
        });
        break;
      case EVENTS.CHECKOUT_STARTED:
        gtag('event', 'begin_checkout', {
          items: data.map(itemToGtag),
          coupon: '',
        });
        break;
      case EVENTS.CHECKOUT_ENVIO_TIPO:
        gtag('event', 'set_checkout_option', {
          checkout_step: 'envio',
          checkout_option: 'tipo',
          value: data,
        });
        break;
      case EVENTS.CHECKOUT_PAGO_CODIGO_DESCUENTO:
        gtag('event', 'checkout_progress', {
          items: data.items.map(itemToGtag),
          coupon: data.codigo,
        });
        break;
      case EVENTS.CHECKOUT_PAGO_SUCCESS:
        gtag('event', 'purchase', {
          transaction_id: data._id,
          value: data.monto.total,
          currency: 'PEN',
          shipping: data.montoEnvio || 0,
          items: data.items.map(itemToGtag),
        });
        break;
      default:
        gtag('event', event.replace(/-/g, '_'), { ...metadata });
        break;
    }
  }
};

// export const identifyUser = ({ cognitoSub, intercomHash, email }) => {
export const identifyUser = ({ cognitoSub, intercomHash, email }) => {
  intercom('boot', {
    app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    user_id: cognitoSub,
    user_hash: intercomHash,
  });
  if (IS_BROWSER && IS_PRODUCTION) {
    import('@sentry/nextjs').then((Sentry) => {
      Sentry.setUser({ email, cognitoSub });
    });
    const gtag = getGtag();
    gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
      // eslint-disable-next-line camelcase
      user_id: cognitoSub,
    });
    gtag('set', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
      // eslint-disable-next-line camelcase
      cognito_sub: cognitoSub,
    });
  }

  userIsIdentified = true;

  const store = getStore();
  const {
    app: { eventsToTrack },
  } = store.getState();
  if (eventsToTrack.length > 0) {
    eventsToTrack.forEach(({ event, options }) => {
      trackEvent(event, options);
    });
    const {
      actions: { clearEventsToTrack },
    } = sliceApp;
    store.dispatch(clearEventsToTrack());
  }
};

export const trackNavigation = (url, options = {}) => {
  trackEvent(EVENTS.VIEW_PAGE, { url, options });
};
