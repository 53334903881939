import { createSlice } from '@reduxjs/toolkit';
import { actionCreatorCreator, extraReducers } from './utils';

export default createSlice({
  name: 'user',
  slice: 'user',
  initialState: {
    data: null,
    loading: true,
    userPerfil: {
      data: null,
      loading: true,
    },
  },
  reducers: {
    setLoading: actionCreatorCreator('loading'),
    setUserData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setUserPerfil: (state, action) => ({
      ...state,
      userPerfil: {
        ...state.userPerfil,
        data: action.payload,
      },
    }),
    setLoadingUserPerfil: (state, action) => ({
      ...state,
      userPerfil: {
        ...state.userPerfil,
        loading: action.payload,
      },
    }),
    setUserDatosField: (state, { payload: { field, value } }) => {
      const nextState = {
        ...state,
        userPerfil: {
          data: {
            ...state.data,
            datos: state.data.datos ? { ...state.data.datos } : {},
          },
        },
      };
      if (!nextState.data.datos) {
        nextState.data.datos = {};
      }

      if (value) {
        nextState.data.datos[field] = value;
      } else {
        delete nextState.data.datos[field];
      }
    },
    setUserDireccionField: (state, { payload: { field, value } }) => {
      const nextState = {
        ...state,
        userPerfil: {
          data: {
            ...state.data,
            direccion: state.data.direccion ? { ...state.data.direccion } : {},
          },
        },
      };

      if (field === 'departamento' || field === 'provincia') {
        delete nextState.data.direccion.distrito;
        if (field === 'departamento') {
          delete nextState.data.direccion.provincia;
        }
      }

      if (value) {
        nextState.data.direccion[field] = value;
      } else {
        delete nextState.data.direccion[field];
      }
    },
  },
  extraReducers,
});
