import { captureException } from '@sentry/nextjs';

const ANONYMOUS_ENDPOINT = process.env.NEXT_PUBLIC_ANONYMOUS_ENDPOINT;

const handleFetchRequest = async (fetchRequest) => {
  try {
    const response = await fetchRequest;

    if (!response.ok) {
      captureException(new Error(`Error! status: ${response.status}`));
    }

    const { data } = await response.json();
    return data;
  } catch (err) {
    captureException(err);
    throw err;
  }
};

export const request = async (endpoint, action, variables = {}, headers = {}) => {
  const req = {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      // eslint-disable-next-line quote-props
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      query: action,
      variables,
    }),
  };
  try {
    return await handleFetchRequest(fetch(endpoint, req));
  } catch (e) {
    console.error(e);
    if (endpoint !== ANONYMOUS_ENDPOINT) {
      try {
        return await handleFetchRequest(fetch(ANONYMOUS_ENDPOINT, req));
      } catch (e2) {
        console.error(e2);
        throw e2;
      }
    } else {
      console.error(e);
      throw e;
    }
  }
};

// eslint-disable-next-line consistent-return
export async function fetcher(args) {
  let endpoint = ANONYMOUS_ENDPOINT;
  let headers = {};

  const { Auth } = await import('@aws-amplify/auth');

  try {
    const currUser = await Auth.currentAuthenticatedUser();
    const token = currUser.signInUserSession.idToken.jwtToken;
    if (token) {
      headers = {
        authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${token}`,
      };
      endpoint = process.env.NEXT_PUBLIC_PUBLIC_ENDPOINT;
    }
  // eslint-disable-next-line no-empty
  } catch (error) {}

  // eslint-disable-next-line no-useless-catch
  try {
    const result = await request(
      endpoint,
      args.query || args.mutation,
      args.variables ?? null,
      headers,
    );
    return result;
  } catch (e) {
    console.error(
      'SENTRY fetcher',
      args.query || args.mutation,
      args.variables ? JSON.stringify(args.variables, null, 4) : null,
    );
  }

  // try {
  // } catch (error) {
  //   const { message, path } = error;
  //   Trackjs.track(new Error(
  //     `[GraphQL error]: ${message}${
  //       path && `, paths: ${path.join(', ')}`
  //     }`,
  //   ));
  //   throw error;
  // }
}

export const config = {
  fetcher,
};
