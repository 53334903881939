import qs from 'qs';
import Router from 'next/router';
import { searchStateToUrl } from '../../../../lib/utils/search';

export const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME;

export const createUrl = (state) => `?${qs.stringify(state)}`;

const isValidSearchState = (searchState, prevSearchState) => {
  if (
    prevSearchState.query
    || (
      searchState.range?.porcentajeRebaja
      && searchState.range.porcentajeRebaja.min === undefined
    )
    || !searchState?.refinementList?.universo
  ) {
    return false;
  }
  return true;
};

const UPDATE_AFTER = 700;
let debouncedSetState;
export const onSearchStateChange = (setSearchState, getSearchState, searchState) => {
  clearTimeout(debouncedSetState);

  debouncedSetState = setTimeout(() => {
    const prevSearchState = getSearchState();
    if (isValidSearchState(searchState, prevSearchState)) {
      const href = searchStateToUrl(searchState);

      Router.push(href, undefined, {
        shallow: true,
      });

      setSearchState(searchState);
    }
  }, UPDATE_AFTER);
};
