import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reduxBatch } from '@manaflair/redux-batch';
import { createWrapper, getStore } from './next-redux-wrapper';

// import { IS_BROWSER } from './utils';
// eslint-disable-next-line import/no-cycle
import reducer from './reducers';

const makeStore = () => configureStore({
  reducer,
  enhancers: [reduxBatch],
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const wrapper = createWrapper(makeStore);

// if (IS_BROWSER && window.Cypress) {
//   window.reduxStore = store;
// }

export { getStore };
