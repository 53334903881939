import { createSlice } from '@reduxjs/toolkit';

import {
  actionCreatorCreator,
  addItem,
  removeItem,
  itemExisteEnArray,
  extraReducers,
} from './utils';
import { PAGO_TIPO, ENVIO_TIPO } from '../GQL_ENUMS';

export default createSlice({
  name: 'checkout',
  slice: 'checkout',
  initialState: {
    loading: true,
    data: {},
    envio: {
      tipo: ENVIO_TIPO.RECOJO_TIENDA,
      direccion: {
        departamento: 'Callao',
        nombres: '',
        apellidos: '',
        numeroDocumento: '',
        telefono1: '',
        direccion: '',
        provincia: '',
        distrito: '',
      },
    },
    costoEnvio: {
      data: 0,
      loading: true,
    },
    boxEnvio: {
      tipo: ENVIO_TIPO.ENVIO,
      direccion: {
        departamento: 'Callao',
        nombres: '',
        apellidos: '',
        numeroDocumento: '',
        telefono1: '',
        direccion: '',
        provincia: '',
        distrito: '',
      },
    },
    boxCostoEnvio: {
      data: 0,
      loading: true,
    },
    usaMismaDireccion: false,
    puntos: {
      usaPuntos: true,
      montoPuntos: 0,
      montoActual: 0,
      loading: true,
    },
    descuentos: {
      codigo: '',
      data: {},
      loading: true,
      error: '',
    },
    pago: {
      tipo: PAGO_TIPO.TARJETA,
      monto: {},
      partido: {
        carrito: true,
        box: false,
      },
    },
    tarjetaDatos: {
      email: '',
    },
    loadingPartido: true,
    notasDeCredito: {
      data: [],
      loading: true,
      porCobrar: [],
    },
  },
  reducers: {
    setLoading: actionCreatorCreator('loading'),
    setData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setEnvioTipo: (state, { payload: tipo }) => ({
      ...state,
      envio: {
        ...state.envio,
        tipo,
      },
    }),
    setEnvioDireccion: (state, action) => ({
      ...state,
      envio: {
        ...state.envio,
        direccion: action.payload,
      },
    }),
    setEnvioDireccionField: (state, { payload: { field, value } }) => {
      const nextState = {
        ...state,
        envio: {
          ...state.envio,
          direccion: state.envio.direccion ? { ...state.envio.direccion } : {},
        },
      };

      if (field === 'departamento' || field === 'provincia') {
        delete nextState.envio.direccion.distrito;
        if (field === 'departamento') {
          delete nextState.envio.direccion.provincia;
        }
      }

      if (value) {
        nextState.envio.direccion[field] = value;
      } else {
        delete nextState.envio.direccion[field];
      }

      return nextState;
    },
    setEnvioCosto: (state, action) => ({
      ...state,
      costoEnvio: {
        ...state.costoEnvio,
        data: action.payload,
      },
    }),
    setLoadingEnvioCosto: (state, action) => ({
      ...state,
      costoEnvio: {
        ...state.costoEnvio,
        loading: action.payload,
      },
    }),
    setPuntos: (state, action) => ({
      ...state,
      puntos: {
        ...state.puntos,
        montoActual: action.payload,
      },
    }),
    setLoadingPuntos: (state, action) => ({
      ...state,
      puntos: {
        ...state.puntos,
        loading: action.payload,
      },
    }),
    setMontoPuntos: (state, { payload: montoPuntos }) => ({
      ...state,
      puntos: {
        ...state.puntos,
        montoPuntos,
        usaPuntos: !!montoPuntos,
      },
    }),
    setUsaPuntos: (state, { payload: usaPuntos }) => ({
      ...state,
      puntos: {
        ...state.puntos,
        montoPuntos: !usaPuntos ? 0 : state.puntos.montoPuntos,
        usaPuntos,
      },
    }),
    setDescuentos: (state, action) => ({
      ...state,
      descuentos: {
        ...state.descuentos,
        data: action.payload,
      },
    }),
    setLoadingDescuentos: (state, action) => ({
      ...state,
      descuentos: {
        ...state.descuentos,
        loading: action.payload,
      },
    }),
    setPagoMonto: (state, action) => ({
      ...state,
      pago: {
        ...state.pago,
        monto: action.payload,
      },
    }),
    setPagoId: (state, action) => ({
      ...state,
      pago: {
        ...state.pago,
        _id: action.payload,
      },
    }),
    setTarjetaDatos: (state, action) => ({
      ...state,
      tarjetaDatos: {
        email: action.payload,
      },
    }),
    setBoxEnvioTipo: (state, { payload: tipo }) => ({
      ...state,
      boxEnvio: {
        ...state.boxEnvio,
        tipo,
      },
    }),
    setBoxEnvioDireccion: (state, action) => ({
      ...state,
      boxEnvio: {
        ...state.boxEnvio,
        direccion: action.payload,
      },
    }),
    setBoxEnvioCosto: (state, action) => ({
      ...state,
      boxCostoEnvio: {
        ...state.boxCostoEnvio,
        data: action.payload,
      },
    }),
    setLoadingBoxEnvioCosto: (state, action) => ({
      ...state,
      boxCostoEnvio: {
        ...state.boxCostoEnvio,
        loading: action.payload,
      },
    }),
    setBoxEnvioDireccionField: (state, { payload: { field, value } }) => {
      const nextState = {
        ...state,
        boxEnvio: {
          ...state.boxEnvio,
          direccion: state.boxEnvio.direccion
            ? { ...state.boxEnvio.direccion }
            : {},
        },
      };

      if (field === 'departamento' || field === 'provincia') {
        delete nextState.boxEnvio.direccion.distrito;
        if (field === 'departamento') {
          delete nextState.boxEnvio.direccion.provincia;
        }
      }

      if (value) {
        nextState.boxEnvio.direccion[field] = value;
      } else {
        delete nextState.boxEnvio.direccion[field];
      }

      return nextState;
    },
    setUsaMismaDireccion: actionCreatorCreator('usaMismaDireccion'),
    setPartidoCarrito: (state, action) => ({
      ...state,
      pago: {
        ...state.pago,
        partido: {
          ...state.pago.partido,
          carrito: action.payload,
        },
      },
    }),
    setPartidoBox: (state, action) => ({
      ...state,
      pago: {
        ...state.pago,
        partido: {
          ...state.pago.partido,
          box: action.payload,
        },
      },
    }),
    setLoadingPartido: actionCreatorCreator('loadingPartido'),
    setDescuentosCodigo: (state, action) => ({
      ...state,
      descuentos: {
        ...state.descuentos,
        codigo: action.payload,
      },
    }),
    setDescuentosError: (state, action) => ({
      ...state,
      descuentos: {
        ...state.descuentos,
        error: action.payload,
      },
    }),
    setNotasDeCredito: (state, action) => ({
      ...state,
      notasDeCredito: {
        ...state.notasDeCredito,
        data: action.payload,
      },
    }),
    setLoadingNotasDeCredito: (state, action) => ({
      ...state,
      notasDeCredito: {
        ...state.notasDeCredito,
        loading: action.payload,
      },
    }),
    addRemoveNotaDeCredito: (state, { payload: notaDeCredito }) => {
      const { porCobrar } = state.notasDeCredito;
      const notaPorCobrar = itemExisteEnArray(porCobrar, notaDeCredito);

      const result = {
        ...state,
        notasDeCredito: {
          ...state.notasDeCredito,
          porCobrar: !notaPorCobrar
            ? addItem(porCobrar, notaDeCredito)
            : removeItem(porCobrar, notaDeCredito),
        },
      };
      return result;
    },
    clearNotasDeCredito: (state) => ({
      ...state,
      notasDeCredito: {
        ...state.notasDeCredito,
        porCobrar: [],
      },
    }),
  },
  extraReducers,
});
