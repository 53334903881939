import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'clone-deep';

import { PAGINATE_LIMIT } from '../CONST';
// eslint-disable-next-line import/no-cycle
import { trackEvent, EVENTS } from '../utils/analytics';
import { actionCreatorCreator, sanitizeArrayPayload, extraReducers } from './utils';
import { ITEM_ONLINE_UNIVERSO } from '../GQL_ENUMS';
import { isNil, difference, omitBy } from '../utils/lodashFunctions';

const clone = (obj) => cloneDeep(omitBy(obj, isNil));
// eslint-disable-next-line default-param-last
const diff = (one = [], other = [], field) => {
  if (one.length >= other.length) {
    return { removed: { [field]: difference(one, other) } };
  }
  return { added: { [field]: difference(other, one) } };
};

const filterActionCreator = (field) => (state, action) => {
  const value = sanitizeArrayPayload(action.payload);
  const filter = {
    ...state.filter,
    [field]: value,
  };
  trackEvent(EVENTS.ITEMS_FILTER, {
    filter: clone(filter),
    ...diff(state.filter[field], value, field),
  });
  return {
    ...state,
    filter,
  };
};

const itemsOnline = createSlice({
  name: 'itemsOnline',
  slice: 'itemsOnline',
  initialState: {
    data: [],
    totalCount: 0,
    loading: false,
    filter: {},
    paginate: { limit: PAGINATE_LIMIT },
    drawerOpen: false,
    loadingStaticPageFilter: true,
    universo: ITEM_ONLINE_UNIVERSO.MUJER,
  },
  reducers: {
    setData: actionCreatorCreator('data'),
    setTotalCount: actionCreatorCreator('totalCount'),
    setLoading: actionCreatorCreator('loading'),
    setFilter: actionCreatorCreator('filter'),
    clearFilter: (state) => {
      trackEvent(EVENTS.ITEMS_FILTER_CLEAR);
      const newState = {
        ...state,
        filter: {},
      };
      delete newState.sort;
      return newState;
    },
    setFilterCategorias: filterActionCreator('categorias'),
    setFilterTallas: filterActionCreator('tallas'),
    setFilterMarcas: filterActionCreator('marcas'),
    setFilterPrecioRango: (state, { payload: precioRango }) => {
      const filter = {
        ...state.filter,
      };
      if (precioRango) {
        filter.precioRango = precioRango;
      } else {
        delete filter.precioRango;
      }
      trackEvent(EVENTS.ITEMS_FILTER, {
        filter: clone(filter),
        changed: { precioRango: precioRango || '' },
      });
      return {
        ...state,
        filter,
      };
    },
    setSort: (state, { payload: sort }) => {
      const newState = {
        ...state,
      };
      if (sort) {
        newState.sort = sort;
      } else {
        delete newState.sort;
      }
      trackEvent(EVENTS.ITEMS_SORT, { sort: sort || '' });
      return newState;
    },
    setPaginate: actionCreatorCreator('paginate'),
    setPaginateCurrentPage: (state, action) => ({
      ...state,
      paginate: {
        ...state.paginate,
        offset: (action.payload - 1) * state.paginate.limit,
      },
    }),
    setDrawerOpen: actionCreatorCreator('drawerOpen'),
    setLoadingStaticPage: actionCreatorCreator('loadingStaticPageFilter'),
    setFilterColecciones: filterActionCreator('colecciones'),
    setFilterProyectos: filterActionCreator('proyectos'),
    setFilterGenero: (state, { payload: genero }) => {
      const filter = {
        ...state.filter,
      };
      if (genero) {
        filter.genero = genero;
      } else {
        delete filter.genero;
      }
      trackEvent(EVENTS.ITEMS_FILTER, {
        filter: clone(filter),
        changed: { genero: genero || '' },
      });
      return {
        ...state,
        filter,
      };
    },
    setFilterEstado: (state, { payload: estado }) => {
      const filter = {
        ...state.filter,
      };
      if (estado) {
        filter.estado = estado;
      } else {
        delete filter.estado;
      }
      trackEvent(EVENTS.ITEMS_FILTER, {
        filter: clone(filter),
        changed: { estado: estado || '' },
      });
      return {
        ...state,
        filter,
      };
    },
    setFilterCategoriasInfantes: filterActionCreator('categoriasInfantes'),
    setUniverso: actionCreatorCreator('universo'),
    setFilterRebajaRango: (state, { payload: rebajaRango }) => {
      const filter = {
        ...state.filter,
      };
      if (rebajaRango) {
        filter.rebajaRango = rebajaRango;
      } else {
        delete filter.rebajaRango;
      }
      trackEvent(EVENTS.ITEMS_FILTER, {
        filter: clone(filter),
        changed: { rebajaRango: rebajaRango || '' },
      });
      return {
        ...state,
        filter,
      };
    },
  },
  extraReducers,
});

export default itemsOnline;
