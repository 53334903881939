import { HYDRATE } from '../next-redux-wrapper';

import { compact } from '../utils/lodashFunctions';

export const actionCreatorCreator = (field) => (state, action) => ({
  ...state,
  [field]: action.payload,
});

export const sanitizeArrayPayload = (payload) => {
  if (!Array.isArray(payload)) {
    return payload ? [payload] : undefined;
  }
  const cPayload = compact(payload);
  return cPayload.length === 0 ? undefined : cPayload;
};

export const addItem = (items, item) => items.concat([item]);

export const removeItem = (items, item) => items.filter((i) => i._id !== item._id);

export const itemExisteEnArray = (items, item) => {
  const index = items.findIndex((p) => p._id === item._id);
  if (index >= 0) {
    return true;
  }
  return false;
};

export const extraReducers = {
  [HYDRATE]: (state, action) => ({
    ...state,
    ...action.payload.some,
  }),
};
