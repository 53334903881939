import { createSlice } from '@reduxjs/toolkit';

import { actionCreatorCreator, extraReducers } from './utils';

const reclamo = createSlice({
  name: 'reclamo',
  slice: 'reclamo',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    setData: actionCreatorCreator('data'),
    setLoading: actionCreatorCreator('loading'),
  },
  extraReducers,
});

export default reclamo;
