import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import 'url-search-params-polyfill';
import { SWRConfig } from 'swr';
import dynamic from 'next/dynamic';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useRouter } from 'next/router';

import { config } from '../lib/swr';
import theme from '../components/utils/theme';
import { wrapper } from '../lib/store';
import { IS_BROWSER } from '../lib/utils';
import * as fbq from '../lib/utils/fbpixel';

import { useCreateStore, Provider as SearchStoreProvider } from '../features/search/store/main';
import imports from '../features/vendor/lib/imports';

import '../styles/globals.css';
import '../styles/amplify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../components/Layout/layout.css';
import '../features/legalPages/components/md.css';
import '../components/ResponsiveNavigation/algolia-theme.css';
import '../components/ResponsiveNavigation/shop.css';
import '../components/ResponsiveNavigation/shop.mobile.css';
import '../components/Search/Pagination.css';
import '../components/Search/CustomSlider.css';

const AuthProvider = dynamic(() => (
  import('../lib/controllers/authConfigure').then(({ authConfigure }) => {
    authConfigure();
    return import('../features/auth/components/AuthContext').then((mod) => mod.AuthProvider);
  })
));

if (!IS_BROWSER) {
  process.on('unhandledRejection', (reason, promise) => {
    console.info('unhandledRejection', reason, promise);
  });
}

// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#insertion-point-jss'),
    );
  }, []);

  const router = useRouter();

  useEffect(() => {
    // This pageview only triggers the first time
    // (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const createStore = useCreateStore(pageProps.initialSearchStoreState ?? {});

  const content = useMemo(
    () => (
      <AuthProvider>
        <SWRConfig value={config}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SearchStoreProvider createStore={createStore}>
              <Component {...pageProps} />
            </SearchStoreProvider>
          </ThemeProvider>
        </SWRConfig>
      </AuthProvider>
    ),
    [createStore, pageProps],
  );

  if (pageProps.isCmsAdmin || pageProps.isCmsPage) {
    const Tina = dynamic(
      () => import('../../.tina/components/TinaDynamicProvider'),
      {
        ssr: false,
      },
    );

    return (
      <>
        <Tina>
          {content}
        </Tina>
        {imports}
      </>
    );
  }

  return (
    <>
      {content}
      {imports}
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

const MyReduxApp = wrapper.withRedux(MyApp);

export default MyReduxApp;
