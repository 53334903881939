import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

import {
  colorPrimary,
  colorSecondary,
  colorBgPrimary,
  colorBgSecondary,
} from './elements/muiStyles';

// import FuturaPTBookOft from '../../typography/FuturaPTBook.otf';
// import FuturaPTBoldOft from '../../typography/FuturaPTBold.otf';

// const futura = {
//   fontFamily: 'FuturaPT',
//   // fontStyle: 'bold',
//   // fontDisplay: 'swap',
//   // fontWeight: 400,
//   src: `
//     local('FuturaPT'),
//     local('FuturaPT-Bold'),
//     url(${FuturaPTBookOft}) format('otf')
//   `,
//   unicodeRange:
//     `U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
// U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF`,
// };

// A custom theme for this app
const theme = createTheme({
  // typography: {
  //   fontFamily: 'FuturaPT, Arial',
  // },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [futura],
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: colorBgPrimary,
      secondary: colorBgSecondary,
    },
  },
});

export default theme;
